<script>
// import Layout from "@/router/layouts/main";
import appConfig from '@/app.config';
// import PageHeader from "@/components/page-header";
import axios from 'axios';
import Swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import footerPeserta from '../../../components/footer-peserta.vue';

/**
 * Dashboard Component
 */
export default {
  page: {
    title: 'Dashboard',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
  components: {
    // Layout,
    // PageHeader,
    footerPeserta,
  },
  data() {
    return {
      title: 'Dashboard',
      items: [
        {
          text: 'Dashboards',
          href: '/',
        },
        {
          text: 'Default',
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      dataListJadwal: [],
      nama_peserta: '-',
      jabatan_peserta: '-',
      data_peserta: null,
      app_sso: '',
      token_jadwal: '',
      isOpen: false,
      configs: {},
      isDone: true,
    };
  },
  methods: {
    async getConfig() {
      const data = await axios({
        method: 'get',
        url: process.env.VUE_APP_BACKEND_URL_VERSION + 'referensi/konfig-login',
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        }
      }).then(response => response.data.data)
      if (data) {
        this.configs = data.referensi
      }
    },
    async saveToLocalStorage() {
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const ssoTokenParam = this.$route.query.sso;
      const isSimulasi = this.$route.query.isSimulation ?? false;

      if (ssoTokenParam) {
        var config_axios = {
          method: 'post',
          url: process.env.VUE_APP_BACKEND_URL_VERSION + 'auth/login-sso',
          data: {
            sso: ssoTokenParam,
          },
          headers: {
            Accept: 'application/json',
          },
        };
        await axios(config_axios)
          .then((response) => {
            let response_data_fix = response.data;
            if (response.data.meta.code == 200) {
              localStorage.setItem(
                'access_token_peserta',
                response.data.data.access_token
              );
              localStorage.setItem('nip_peserta', response.data.data.user.nip);
              localStorage.setItem(
                'nama_peserta',
                response.data.data.user.nama
              );
              localStorage.setItem(
                'jabatan_peserta',
                response.data.data.user.jabatan_nama
              );
              localStorage.setItem(
                'data_peserta',
                JSON.stringify(response.data.data.user)
              );
              localStorage.setItem('is_simulasi', isSimulasi);
              (this.nama_peserta = localStorage.nama_peserta),
                (this.jabatan_peserta = localStorage.jabatan_peserta);
              this.data_peserta = JSON.parse(localStorage.data_peserta);
              this.app_sso = response.data.data.app_sso;
              this.token_jadwal = response.data.data.token_jadwal;
              localStorage.setItem('app_sso', this.app_sso);
              localStorage.setItem('app_sso', this.app_sso);
              localStorage.setItem('token_jadwal', this.token_jadwal);
              // console.log(response_data_fix);
              this.$router.replace({query: null});
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response_data_fix.data.message,
              });
            }
            Swal.close();
            this.getJadwalPeserta();
          })
          .catch((errors) => {
            const message = errors.response.data.data?.message;
            const site = errors.response.data.data?.site;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: message,
              showCancelButton: false,
              confirmButtonText: 'Oke',
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = site;
              }
            });
          });
      } else if (localStorage.access_token_peserta) {
        this.nama_peserta = localStorage.nama_peserta;
        this.jabatan_peserta = localStorage.jabatan_peserta;
        this.data_peserta = JSON.parse(localStorage.data_peserta);
        this.getJadwalPeserta();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi kesalahan',
          text: 'Silahkan login ulang',
          timer: 3000,
        }).then((response) => {
          console.log(response);
          history.forward();
        });
      }
    },

    async getJadwalPeserta() {
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      var config_axios = {
        method: 'get',
        url: process.env.VUE_APP_BACKEND_URL_VERSION + 'peserta/jadwal-peserta',
        params: {
          nip: localStorage.nip_peserta,
          token_jadwal: localStorage.token_jadwal,
        },
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + localStorage.access_token_peserta,
        },
      };
      await axios(config_axios)
        .then(async (response) => {
          let response_data_fix = response.data;
          if (response.data.meta.code == 200) {
            this.dataListJadwal = response.data.data;
            console.log(this.dataListJadwal.length > 0 && this.dataListJadwal[0].status_jadwal == 2)
            if (
              this.dataListJadwal.length > 0 &&
              this.dataListJadwal[0].status_jadwal == 2
            ) {
              await Swal.fire({
                icon: 'error',
                title: 'Jadwal telah selesai dan pastikan file patch SEB sudah sesuai jadwal pelaksanaan',
              }).then(async (result) => {
                if (result.isConfirmed) {
                  await this.logout();
                }
              });
            }
            this.isDone = false;
            console.log(this.dataListJadwal.length);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response_data_fix.data.message,
            });
          }
          Swal.close();
        })
        .catch((errors) => {
          console.log(errors);
          Swal.close();
          if (errors.response.status == 403) {
            let timerInterval;
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Silahkan Login Ulang',
              timer: 2000,
              timerProgressBar: true,
              showCancelButton: false,
              showConfirmButton: false,
              willClose: () => {
                clearInterval(timerInterval);
              },
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                let url =
                  localStorage.app_sso === 'catalyst'
                    ? 'https://catalyst.pln.co.id?seb=assesmen_hc_plnups'
                    : 'https://sertifikasi.pln-pusdiklat.co.id/cermat/login';
                window.location.href = url;
              }
            });
          }
        });
    
      this.toggleFirstBatch();
    },

    formatDate(dateString) {
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },

    hapusLocalStorage() {
      Swal.fire({
        icon: 'question',
        title: 'Anda yakin akan logout ?',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
      }).then((response) => {
        if (response.isConfirmed) {
          this.logout();
        }
      });
    },
    async logout() {
      let token_jadwal = localStorage.token_jadwal ?? '';
      let nip_peserta = localStorage.nip_peserta ?? '';
      if (localStorage.nip_peserta) {
        axios({
          method: 'post',
          url: process.env.VUE_APP_BACKEND_URL_VERSION + 'auth/logout-sso',
          data: {
            nip: nip_peserta,
          },
          headers: {
            Authorization: 'Bearer ' + localStorage.access_token_peserta,
          },
        });
      }
      localStorage.clear();
      // if (this.app_sso === "catalyst") {
      // let url = "http://[::1]/midpln/catalyst/login/logout?seb=" + token_jadwal; // local
      let url =
        process.env.VUE_APP_CATALYST_URL + 'login/logout?seb=' + token_jadwal; // dev
      // let url = "login/logout?seb=" + token_jadwal; // prod
      window.location.href = url;
      // }
    },
    async toggleFirstBatch() {
      if (this.dataListJadwal.length > 0 && !this.isDone) {
        await this.toggleAccordion(this.dataListJadwal[0].idjadwal_peserta);
      }
    },

    async toggleAccordion(id) {
      Swal.fire({
        title: 'Loading...',
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      const collapseElement = document.getElementById('collapse-' + id);
      if (collapseElement) {
        // Cek apakah elemen memiliki kelas show
        const hasShowClass = collapseElement.classList.contains('show');
        // Jika sudah memiliki kelas show, hapus kelas tersebut, jika tidak, tambahkan
        if (hasShowClass) {
          Swal.close();
          collapseElement.classList.remove('show');
        } else {
          const selectedJadwal = this.dataListJadwal.find(
            (jadwal) => jadwal.idjadwal_peserta === id
          );
          // Periksa apakah jadwal ditemukan
          if (selectedJadwal) {
            for (const kompetensi of selectedJadwal.list_kompetensi) {
              try {
                // Panggil API untuk mendapatkan data soal
                const response = await axios.get(
                  process.env.VUE_APP_BACKEND_URL_VERSION +
                  'peserta/jadwal-peserta/list-soal-peserta',
                  {
                    params: {
                      id_kompetensi: kompetensi.idkompetensi,
                      idjadwal: selectedJadwal.idjadwal,
                      idjadwal_peserta: selectedJadwal.idjadwal_peserta,
                      level: kompetensi.level_kompetensi_idlevel_kompetensi,
                    },
                    headers: {
                      Accept: 'application/json',
                      Authorization:
                        'Bearer ' + localStorage.access_token_peserta,
                    },
                  }
                );
                const responseData = response.data.data;
                console.log(responseData.length);

                if (responseData.length < 1) {
                  kompetensi.status_peserta.status = 0;
                }
              } catch (error) {
                if (error.response && error.response.status === 500) {
                  kompetensi.status_peserta.status = 0;
                  console.log('Error 500: Internal Server Error');
                } else {
                  kompetensi.status_peserta.status = 0;
                  console.log('Error:', error.message);
                }
              }
            }
          }
          Swal.close();
          collapseElement.classList.add('show');
        }
      }
    },

    pindahKeHalamanTujuan(
      id_kompetensi,
      id_jadwal_peserta,
      id_jadwal,
      id_jadwal_batch,
      timer,
      level,
      id_status,
      nama_kompetensi
    ) {
      Swal.fire({
        title: 'Pakta Integritas',
        width: '800px',
        html:
          '<div style="display:flex; align-items:center;">' +
          '<div style="margin-left: 10px; width: 100%; font-size: 12px">' +
          '<p style="text-align: justify;">Dengan memperhatikan prinsip integritas dan etika dalam rangka pelaksanaan Asesmen Hard Competency, dengan sepenuh kesediaan dan komitmen saya menyatakan bahwa:</p>' +
          '<ol style="text-align: justify;">' +
          '<li>Saya bersedia mentaati dan melaksanakan semua instruksi dan tata tertib yang telah ditetapkan selama berlangsungnya kegiatan asesmen ini.</li>' +
          '<li>Saya akan menjaga kerahasiaan semua informasi yang saya peroleh selama kegiatan asesmen ini dengan tidak akan menyimpan, memperbanyak maupun mengedarkan soal asesmen yang saya peroleh dalam bentuk apapun melalui media apapun.</li>' +
          '<li>Saya tidak akan terlibat dalam tindakan curang seperti; mencontek, berkomunikasi dengan peserta lain, atau menggunakan bahan yang tidak diizinkan selama kegiatan asesmen ini.</li>' +
          '<li>Saya menerima hasil asesmen ini dan mempercayakan seluruh hasil asesmen untuk dikelola oleh PT PLN (Persero) Pusat Pendidikan dan Pelatihan dan/atau pihak yang terkait.</li>' +
          '<li>Saya bersedia untuk memberikan umpan balik atau evaluasi terhadap proses asesmen ini dengan tujuan untuk meningkatkan kualitas asesmen di masa mendatang.</li>' +
          '</ol>' +
          '<p style="text-align: justify;"><input type="checkbox" id="checkbox"> Saya menyatakan bahwa saya telah membaca, memahami dan setuju untuk mematuhi semua poin yang tercantum dalam Pakta Integritas ini. Demikian Pakta Integritas ini saya buat dengan sebenar-benarnya tanpa ada unsur paksaan dari pihak manapun.</p>' +
          '</div>' +
          '</div>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Lanjutkan',
        preConfirm: () => {
          const checkbox = document.getElementById('checkbox');
          if (checkbox.checked) {
            return true;
          } else {
            Swal.showValidationMessage(
              'Anda harus menyetujui untuk melanjutkan'
            );
            return false;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          var config_store_data = {
            method: 'put',
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              'peserta/jadwal-peserta/update-jawaban-peserta/' +
              id_status,
            data: {
              id_jadwal: id_jadwal,
              idjadwal_peserta: id_jadwal_peserta,
              idjadwal_batch: id_jadwal_batch,
              idkompetensi: id_kompetensi,
              level: level,
              status: 2,
              // kritik_saran: this.id_jadwal_batch,
            },
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.access_token_peserta,
            },
          };
          var config_update_mulai_mengerjakan = {
            method: 'put',
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              'peserta/jadwal-peserta/update-mulai-mengerjakan/' +
              id_status,

            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.access_token_peserta,
            },
          };
          axios(config_update_mulai_mengerjakan)
            .then(() => {
            })
            .catch((error) => {
              this.axiosCatchError = error.response.data;
              if (error.response.status == 403) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Silahkan Login Ulang',
                  timer: 3000,
                  showConfirmButton: false,
                }).then(() => {
                  let url =
                    localStorage.app_sso === 'catalyst'
                      ? 'https://catalyst.pln.co.id?seb=assesmen_hc_plnups'
                      : 'https://sertifikasi.pln-pusdiklat.co.id/cermat/login';
                  window.location.href = url;
                });
              }
              Swal.close();
            });
          axios(config_store_data)
            .then(async () => {
              let countDownBatch = -1;
              const dataBatch = await axios({
                method: 'get',
                url: 
                  process.env.VUE_APP_BACKEND_URL_VERSION +
                  `referensi/get-batch/${id_jadwal}/${id_jadwal_batch}`,
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${localStorage.access_token}`,
                },
              })
                .then(response => response.data.data)
                .catch((err) => {
                  console.log('err get batch', err);
                  return false
                });
              if (dataBatch) {
                countDownBatch = dataBatch.countdown;
              }

              console.log(dataBatch);
              // console.log(response);
              let param_text =
                id_kompetensi +
                '_' +
                id_jadwal_peserta +
                '_' +
                id_jadwal +
                '_' +
                id_jadwal_batch +
                '_' +
                timer +
                '_' +
                nama_kompetensi +
                '_' +
                id_kompetensi +
                '_' +
                level +
                '_' +
                id_status +
                '_' +
                countDownBatch;
              console.log(param_text);
              let my_param = CryptoJS.AES.encrypt(
                param_text,
                '4lfahumaNeEd5ME!'
              ).toString();

              // Menggunakan arrow function untuk mempertahankan konteks this
              Swal.close();
              this.$router.push({
                name: 'dashboard-soal',
                query: {soal: my_param},
              });
            })
            .catch((error) => {
              this.axiosCatchError = error.response.data;
              if (error.response.status == 403) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Silahkan Login Ulang',
                  timer: 3000,
                  showConfirmButton: false,
                }).then(() => {
                  let url =
                    localStorage.app_sso === 'catalyst'
                      ? 'https://catalyst.pln.co.id?seb=assesmen_hc_plnups'
                      : 'https://sertifikasi.pln-pusdiklat.co.id/cermat/login';
                  window.location.href = url;
                });
              }
              Swal.close();
            });
        }
      });
    },

    remidi(
      id_kompetensi,
      id_jadwal_peserta,
      id_jadwal,
      id_jadwal_batch,
      timer,
      level,
      id_status,
      nama_kompetensi
    ) {
      Swal.fire({
        title: 'Pakta Integritas',
        width: '800px',
        html:
          '<div style="display:flex; align-items:center;">' +
          '<div style="margin-left: 10px; width: 100%; font-size: 12px">' +
          '<p style="text-align: justify;">Dengan memperhatikan prinsip integritas dan etika dalam rangka pelaksanaan Asesmen Hard Competency, dengan sepenuh kesediaan dan komitmen saya menyatakan bahwa:</p>' +
          '<ol style="text-align: justify;">' +
          '<li>Saya bersedia mentaati dan melaksanakan semua instruksi dan tata tertib yang telah ditetapkan selama berlangsungnya kegiatan asesmen ini.</li>' +
          '<li>Saya akan menjaga kerahasiaan semua informasi yang saya peroleh selama kegiatan asesmen ini dengan tidak akan menyimpan, memperbanyak maupun mengedarkan soal asesmen yang saya peroleh dalam bentuk apapun melalui media apapun.</li>' +
          '<li>Saya tidak akan terlibat dalam tindakan curang seperti; mencontek, berkomunikasi dengan peserta lain, atau menggunakan bahan yang tidak diizinkan selama kegiatan asesmen ini.</li>' +
          '<li>Saya menerima hasil asesmen ini dan mempercayakan seluruh hasil asesmen untuk dikelola oleh PT PLN (Persero) Pusat Pendidikan dan Pelatihan dan/atau pihak yang terkait.</li>' +
          '<li>Saya bersedia untuk memberikan umpan balik atau evaluasi terhadap proses asesmen ini dengan tujuan untuk meningkatkan kualitas asesmen di masa mendatang.</li>' +
          '</ol>' +
          '<p style="text-align: justify;"><input type="checkbox" id="checkbox"> Saya menyatakan bahwa saya telah membaca, memahami dan setuju untuk mematuhi semua poin yang tercantum dalam Pakta Integritas ini. Demikian Pakta Integritas ini saya buat dengan sebenar-benarnya tanpa ada unsur paksaan dari pihak manapun.</p>' +
          '</div>' +
          '</div>',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Uji Ulang',
        preConfirm: () => {
          const checkbox = document.getElementById('checkbox');
          if (checkbox.checked) {
            return true;
          } else {
            Swal.showValidationMessage(
              'Anda harus menyetujui untuk melanjutkan'
            );
            return false;
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Loading...',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          var config_uji_ulang = {
            method: 'put',
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              'peserta/jadwal-peserta/update-remidi/' +
              id_status,

            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.access_token_peserta,
            },
          };
          axios(config_uji_ulang)
            .then(() => {
            })
            .catch((error) => {
              this.axiosCatchError = error.response.data;
              if (error.response.status == 403) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Silahkan Login Ulang',
                  timer: 3000,
                  showConfirmButton: false,
                }).then(() => {
                  let url =
                    localStorage.app_sso === 'catalyst'
                      ? 'https://catalyst.pln.co.id?seb=assesmen_hc_plnups'
                      : 'https://sertifikasi.pln-pusdiklat.co.id/cermat/login';
                  window.location.href = url;
                });
              }
              Swal.close();
            });
          var config_update_mulai_mengerjakan = {
            method: 'put',
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              'peserta/jadwal-peserta/update-mulai-mengerjakan/' +
              id_status,

            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.access_token_peserta,
            },
          };
          var config_store_data = {
            method: 'put',
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              'peserta/jadwal-peserta/update-jawaban-peserta/' +
              id_status,
            data: {
              id_jadwal: id_jadwal,
              idjadwal_peserta: id_jadwal_peserta,
              idjadwal_batch: id_jadwal_batch,
              idkompetensi: id_kompetensi,
              level: level,
              status: 2,
              // kritik_saran: this.id_jadwal_batch,
            },
            headers: {
              Accept: 'application/json',
              Authorization: 'Bearer ' + localStorage.access_token_peserta,
            },
          };
          axios(config_update_mulai_mengerjakan)
            .then(() => {
            })
            .catch((error) => {
              this.axiosCatchError = error.response.data;
              if (error.response.status == 403) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Silahkan Login Ulang',
                  timer: 3000,
                  showConfirmButton: false,
                }).then(() => {
                  let url =
                    localStorage.app_sso === 'catalyst'
                      ? 'https://catalyst.pln.co.id?seb=assesmen_hc_plnups'
                      : 'https://sertifikasi.pln-pusdiklat.co.id/cermat/login';
                  window.location.href = url;
                });
              }
              Swal.close();
            });
          axios(config_store_data)
            .then((response) => {
              console.log(response);
              let param_text =
                id_kompetensi +
                '_' +
                id_jadwal_peserta +
                '_' +
                id_jadwal +
                '_' +
                id_jadwal_batch +
                '_' +
                timer +
                '_' +
                nama_kompetensi +
                '_' +
                id_kompetensi +
                '_' +
                level +
                '_' +
                id_status;
              let my_param = CryptoJS.AES.encrypt(
                param_text,
                '4lfahumaNeEd5ME!'
              ).toString();

              // Menggunakan arrow function untuk mempertahankan konteks this
              Swal.close();
              this.$router.push({
                name: 'dashboard-soal',
                query: {soal: my_param},
              });
            })
            .catch((error) => {
              this.axiosCatchError = error.response.data;
              if (error.response.status == 403) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Silahkan Login Ulang',
                  timer: 3000,
                  showConfirmButton: false,
                }).then(() => {
                  let url =
                    localStorage.app_sso === 'catalyst'
                      ? 'https://catalyst.pln.co.id?seb=assesmen_hc_plnups'
                      : 'https://sertifikasi.pln-pusdiklat.co.id/cermat/login';
                  window.location.href = url;
                });
              }
              Swal.close();
            });
        }
      });
    },

    isDateBetweenStartAndEnd(waktu_mulai, waktu_selesai) {
      const currentDate = new Date();
      const startDate = new Date(waktu_mulai);
      const endDate = new Date(waktu_selesai);
      // console.log(currentDate);
      // console.log(startDate);
      // console.log(endDate);
      console.log(
        'isDateBetweenStartAndEnd',
        currentDate.getTime() >= startDate.getTime() &&
        currentDate.getTime() <= endDate.getTime()
      );
      return (
        currentDate.getTime() >= startDate.getTime() &&
        currentDate.getTime() <= endDate.getTime()
      );
    },
  },

  async mounted() {
    await this.saveToLocalStorage();
    await this.getConfig();
  },
};
</script>

<template>
  <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
  <div class="card">
    <div
      class="card-header bg-primary text-white font-size-20 font-weight-semibold"
      style="text-align: center; height: 50px"
    >
      <img src="@/assets/images/logo-pln.png" height="40" style="position: absolute; left: 12px; top: 5px">
      Asesmen Hard Competency
    </div>
  </div>
  <div class="container-fluid">
    <div class="row mt-4">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header bg-primary text-white font-size-16">
            Informasi Peserta
          </div>
          <div
            class="card-body d-flex flex-column"
            style="height: 300px; overflow-y: auto"
          >
            <div class="row mb-0">
              <div
                class="col-sm-3 d-flex justify-content-between"
                style="padding-right: 1px"
              >
                <p class="font-size-16">Nama</p>
                <p class="font-size-16">:</p>
              </div>
              <div class="col-sm-9">
                <p class="font-size-16">
                  {{ nama_peserta }}
                </p>
              </div>
            </div>
            <div class="row mb-0">
              <div
                class="col-sm-3 d-flex justify-content-between"
                style="padding-right: 1px"
              >
                <p class="font-size-16">NIP</p>
                <p class="font-size-16">:</p>
              </div>
              <div class="col-sm-9">
                <p class="font-size-16">
                  {{ data_peserta?.nip }}
                </p>
              </div>
            </div>
            <div class="row mb-0">
              <div
                class="col-sm-3 d-flex justify-content-between"
                style="padding-right: 1px"
              >
                <p class="font-size-16">Jabatan</p>
                <p class="font-size-16">:</p>
              </div>
              <div class="col-sm-9">
                <p class="font-size-16">
                  {{ data_peserta?.jabatan }}
                </p>
              </div>
            </div>
            <div class="row mb-0">
              <div
                class="col-sm-3 d-flex justify-content-between"
                style="padding-right: 1px"
              >
                <p class="font-size-16">Unit</p>
                <p class="font-size-16">:</p>
              </div>
              <div class="col-sm-9">
                <p class="font-size-16">
                  {{ data_peserta?.u_namaunit1 }}
                </p>
              </div>
            </div>
            <!-- <div class="mb-2 text-center">
                <img v-if="pathPhoto == null" src="@/assets/images/users/avatar-1.jpg" width="150"
                  class="img-thumbnail rounded-circle" />
                <img v-else v-bind:src="pathPhotoFull" width="150" alt="" class="img-thumbnail rounded-circle" />
              </div> -->
            <div class="row mt-auto">
              <button
                class="btn btn-danger rounded-button ms-auto"
                @click="hapusLocalStorage()"
              >
                Logout
              </button>
            </div>
            <!-- <button v-if="app_sso" class="btn btn-danger rounded-button my-4" @click="hapusLocalStorage()">Kembali ke {{ app_sso ? app_sso : "" }}</button> -->
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-header bg-primary text-white font-size-16">
            Informasi Ujian
          </div>
          <div class="card-body" style="height: 300px; overflow-y: auto">
            <div class="info-ujian" v-html="configs?.INFO_UJIAN"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="card mb-0" style="border-radius: 5px">
        <div
          class="card-header bg-primary text-white font-size-16"
          style="border-radius: 5px"
        >
          List Jadwal
        </div>
        <div class="card-body">
          <div v-for="item in dataListJadwal" :key="item.idjadwal_peserta">
            <div
              class="accordion mb-4"
              :id="'accordion-' + item.idjadwal_peserta"
            >
              <div class="accordion-item">
                <h2
                  class="accordion-header"
                  :id="'heading-' + item.idjadwal_peserta"
                >
                  <button
                    class="accordion-button"
                    type="button"
                    :data-bs-target="'#collapse-' + item.idjadwal_peserta"
                    aria-expanded="true"
                    :aria-controls="'collapse-' + item.idjadwal_peserta"
                    @click="toggleAccordion(item.idjadwal_peserta)"
                  >
                    {{ item.keterangan.toUpperCase() }} -
                    {{ item.nama_batch }}
                  </button>
                </h2>
                <div
                  :id="'collapse-' + item.idjadwal_peserta"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'heading-' + item.idjadwal_peserta"
                  :data-bs-parent="'#accordion-' + item.idjadwal_peserta"
                  :class="{ show: isOpen }"
                >
                  <div class="accordion-body">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table
                            class="table align-middle table-bordered font-size-14"
                          >
                            <tbody>
                            <tr>
                              <td>
                                <h4 class="mb-2">
                                  Batch : {{ item.nama_batch }}
                                </h4>
                                <div class="row">
                                  <div class="col-xl-4">Tanggal Mulai</div>
                                  <div class="col-xl-7">
                                    : {{ formatDate(item.waktu_mulai) }}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-xl-4">Tanggal Selesai</div>
                                  <div class="col-xl-7">
                                    : {{ formatDate(item.waktu_selesai) }}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-xl-4">
                                    Jabatan yang Asses
                                  </div>
                                  <div class="col-xl-7">
                                    : {{ item.jabatan_nama }}
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-xl-4">
                                    Kode Jabatan
                                  </div>
                                  <div class="col-xl-7">
                                    : {{ item.kode_job }} - {{ item.nama_job }}
                                  </div>
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <!-- Kompetensi Table -->
                    <div>
                      <table
                        v-if="
                          isDateBetweenStartAndEnd(
                            item.waktu_mulai,
                            item.waktu_selesai
                          )
                        "
                        class="table align-middle table-striped table-bordered font-size-14 table-sm"
                        style="width: 100%"
                      >
                        <thead class="table-primary">
                        <tr>
                          <th class="text-center">Kompetensi</th>
                          <th class="text-center" width="5%">
                            Level
                          </th>
                          <th class="text-center" width="10%">
                            Tipe Kompetensi
                          </th>
                          <th class="text-center" width="10%" v-if="item.is_petunjuk == 1">Skor</th>
                          <th class="text-center" width="15%">Mulai Mengerjakan</th>
                          <th class="text-center" width="15%">Status</th>

                          <th class="text-center" width="80px">Aksi</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                          v-for="kompetensi in item.list_kompetensi"
                          :key="kompetensi.idkompetensi"
                        >
                          <td>{{ kompetensi.nama_kompetensi }}</td>
                          <td style="text-align: center">
                            {{
                              kompetensi.level_kompetensi_idlevel_kompetensi
                            }}
                          </td>
                          <td>
                            {{ kompetensi.status_peserta.tipe_kompetensi }}
                          </td>
                          <td v-if="item.is_petunjuk == 1">
                            {{
                              kompetensi.skor_kompetensi?.prosentase_skor ||
                              '0'
                            }}
                          </td>
                          <td>
                            {{
                              kompetensi.status_peserta.waktu_mengerjakan ??
                              '-'
                            }}
                          </td>
                          <td v-if="kompetensi.status_peserta.status === 1">
                            <i>Belum Mengerjakan</i>
                          </td>
                          <td
                            v-else-if="kompetensi.status_peserta.status === 2"
                          >
                            <i>Sedang mengerjakan</i>
                          </td>

                          <td
                            v-else-if="
                                kompetensi.status_peserta.status === 3 &&
                                kompetensi.status_peserta.remidi === 0
                              "
                          >
                            <i>Selesai</i>
                          </td>
                          <td
                            v-else-if="
                                kompetensi.status_peserta.status === 3 &&
                                kompetensi.status_peserta.remidi > 0
                              "
                          >
                            <i>Uji Ulang</i>
                          </td>
                          <td v-else>-</td>
                          <td>
                            <button
                              v-if="kompetensi.status_peserta.status === 1"
                              type="button"
                              class="btn btn-primary btn-sm"
                              style="width: 150px"
                              :disabled="
                                item.status_jadwal != 1 || 
                                !isDateBetweenStartAndEnd(
                                  item.waktu_mulai,
                                  item.waktu_selesai
                                )
                              "
                              @click="
                                  pindahKeHalamanTujuan(
                                    kompetensi.idkompetensi,
                                    item.idjadwal_peserta,
                                    item.idjadwal,
                                    item.jadwal_batch_idjadwal_batch,
                                    kompetensi.timer,
                                    kompetensi.level_kompetensi_idlevel_kompetensi,
                                    kompetensi.status_peserta.idstatus_peserta,
                                    kompetensi.nama_kompetensi
                                  )
                                "
                            >
                              Mulai
                            </button>
                            <button
                              v-else-if="
                                  kompetensi.status_peserta.status === 2
                                "
                              type="button"
                              class="btn btn-warning btn-sm"
                              style="width: 150px"
                              @click="
                                  pindahKeHalamanTujuan(
                                    kompetensi.idkompetensi,
                                    item.idjadwal_peserta,
                                    item.idjadwal,
                                    item.jadwal_batch_idjadwal_batch,
                                    kompetensi.timer,
                                    kompetensi.level_kompetensi_idlevel_kompetensi,
                                    kompetensi.status_peserta.idstatus_peserta,
                                    kompetensi.nama_kompetensi
                                  )
                                "
                            >
                              Lanjutkan
                            </button>
                            <button
                              v-else-if="
                                  kompetensi.status_peserta.status === 3 &&
                                  kompetensi.status_peserta.remidi == 2
                                "
                              type="button"
                              class="btn btn-success btn-sm"
                              style="width: 150px"
                            >
                              Selesai
                            </button>
                            <!-- <button
                                v-else-if="
                                  kompetensi.status_peserta.status === 3 &&
                                  kompetensi.status_peserta.remidi < 2
                                "
                                type="button"
                                class="btn btn-warning btn-sm"
                                style="width: 150px"
                                @click="
                                  remidi(
                                    kompetensi.idkompetensi,
                                    item.idjadwal_peserta,
                                    item.idjadwal,
                                    item.jadwal_batch_idjadwal_batch,
                                    kompetensi.timer,
                                    kompetensi.level_kompetensi_idlevel_kompetensi,
                                    kompetensi.status_peserta
                                      .idstatus_peserta,
                                    kompetensi.nama_kompetensi
                                  )
                                "
                              >
                                Uji Ulang
                              </button> -->
                            <span v-else>-</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr class="mb-4"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer-peserta></footer-peserta>
  <!-- end row -->
  <!-- </Layout> -->
</template>
<style scoped>
.nav-head {
  max-width: 1000px !important;
}

.search-pad {
  padding-left: 5px !important;
}

.input-group {
  display: flow;
}

.search-box {
  display: flow;
  align-items: center;
  background-color: #f8f8fb;
  border-radius: 40px;
}

.search-icon {
  position: static;
  line-height: 30px;
}

.search-input input {
  width: -webkit-fill-available;
  border: none;
  outline: none;
  background-color: unset;
  padding: 5px 0px 5px 15px;
}

.position-relative {
  position: relative;
}

.span-notif {
  position: absolute;
  top: 0;
  left: 8px;
  font-size: 8px;
}

.icon-notif {
  position: relative;
  top: 5px;
}

.nav-pills .nav-link {
  border-radius: 5rem;
}

.rounded-button {
  width: 100%;
  padding: 10px;
  border: none;
  /* Warna latar belakang sesuaikan dengan kebutuhan */
  color: #fff;
  /* Warna teks sesuaikan dengan kebutuhan */
  font-size: 16px;
  /* Ukuran font sesuaikan dengan kebutuhan */
  cursor: pointer;
  outline: none;
}
</style>
